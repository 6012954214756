import * as client_hooks from '../../../src/admin/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/(app)": [~7,[2]],
		"/callback": [42],
		"/(app)/companies": [~8,[2]],
		"/(app)/companies/wizard": [~10,[2]],
		"/(app)/companies/[id]": [~9,[2]],
		"/(app)/customers": [~11,[2]],
		"/(app)/data": [~12,[2]],
		"/(app)/data/bloomberg/costs": [~13,[2]],
		"/(app)/data/bloomberg/fields": [~14,[2]],
		"/(app)/data/bloomberg/instruments/view": [~15,[2]],
		"/(app)/data/bloomberg/instruments/wizard": [~16,[2]],
		"/(app)/data/bloomberg/warnings": [~17,[2]],
		"/(app)/data/ca100": [~18,[2]],
		"/(app)/data/sync": [~19,[2]],
		"/(app)/documents": [~20,[2]],
		"/(app)/documents/coverage": [~21,[2]],
		"/(app)/documents/crawlers/doc_errors": [~22,[2]],
		"/(app)/documents/crawlers/errors": [~23,[2]],
		"/(app)/documents/crawlers/logs": [~24,[2]],
		"/(app)/documents/overview": [~25,[2]],
		"/(app)/fdts": [26,[2]],
		"/(app)/forecasts": [27,[2]],
		"/(app)/forecasts/stella/company-parameters": [28,[2,3,4]],
		"/(app)/forecasts/stella/company-parameters/[id]": [~29,[2,3,4,5]],
		"/(app)/forecasts/stella/input-convention-checker": [30,[2,3]],
		"/(app)/forecasts/stella/multiple-simulations": [~31,[2,3]],
		"/(app)/forecasts/stella/parameter-definitions": [~32,[2,3]],
		"/(app)/forecasts/stella/simulations": [~33,[2,3]],
		"/(app)/forecasts/stella/simulations/[id]": [~34,[2,3]],
		"/(app)/forecasts/stella/templates": [~35,[2,3]],
		"/(app)/forecasts/stella/ultimate-forecasting-tool": [~36,[2,3]],
		"/(app)/industries": [~37,[2,6]],
		"/(app)/industries/[id]": [~38,[2,6]],
		"/login": [~43],
		"/logout": [44],
		"/(app)/tools": [39,[2]],
		"/(app)/tools/loggers": [~41,[2]],
		"/(app)/tools/log": [40,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';